/* You can add global styles to this file, and also import other style files */

* {
  box-sizing: border-box;
}

html,
body {
  min-height: 100vh;
  margin: 0;
  padding: 0;
}

body {
  background: #ECEFF1;
  font-family: Roboto, 'Helvetica Neue', sans-serif;
}

main {
  margin: 0 1rem;
}

.fullWidth {
  width: 100%;
}